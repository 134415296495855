import React, {useState} from 'react'
import mainservice from '../../services/MainService'
import UserDTO, { IUser, PrivacyRuleList, UsersDTO } from '../../DTO/UserDTO'
import BookDTO, { IBook } from '../../DTO/BookDTO'
import TagDTO, { ITag } from '../../DTO/TagDTO'
import { IUnit } from '../../DTO/UnitDTO'
import InputA from '../InputA/InputA'
import { Clock, Trash, Send, Star, User, Mail, Slash, Info, Printer, Home, Cloud } from 'react-feather'
import UnitDD from '../Unit/UnitDD'
import { Getter } from '../../services/ComService'
import Spinner from '../Spinner/Spinner'
import './UserList.scss'

type TRole = 'ANY' | 'USER' | 'ADMIN' | 'UNREGISTERED' | 'PASSWORDRESET' | 'selfRegisterNotEndorsed' | 'selfRegisterEndorsed'

type Props = {
}

type State = {
  currentTag: string,
  tags: TagDTO[],
  books: BookDTO[],
  roleFilter: TRole,
  showEmailList: boolean,
  contactPreferenceFilterValue: number,
  needle: string,
  activeDropDown: string
}

type IdName = {
  id: number,
  name: string
}

type ServerResult = {
  users: IUser[],
  tagList: IdName[],
  books: IBook[],
  groups: IdName[],
  units: IUnit[],
}

class Marked {
  items: number[] = []
  constructor() {

  }
  list() {
    return this.items
  }
  set(id: number) {
    if (!this.has(id)) {
      this.items.push(id)
    }
  }
  toggle(id: number) {
    if (!this.has(id)) {
      this.items.push(id)
    } else {
      this.delete(id)
    }
  }
  has(id: number) {
    return this.items.indexOf(id) > -1
  }
  hasOne(ids: number[]) {
    return (this.items.length === 0) ||
      ids.some(i => this.items.indexOf(i) > -1)
  }
  hasEvery(ids: number[]) {
    return (this.items.length === 0) ||
      this.items.every(i => ids.indexOf(i) > -1)
  }
  delete(id: number) {
    this.items = this.items.filter(i => i !== id)
  }
  size() {
    return this.items.length
  }
  empty() {
    return this.size() === 0
  }
  reset() {
    this.items = []
  }
}

export default class UserList extends React.Component<Props, State> {
  marked = new Marked()
  unitFilter = new Marked()
  currentTags = new Map()
  users = new UsersDTO()
  // setAvailableUserForUnits: (keys: AvailableUser[]) => void = (_k) => {}
  constructor(props: Props) {
    super(props)
    this.state = {
      currentTag: '',
      tags: [],
      books: [],
      roleFilter: 'ANY',
      showEmailList: false,
      contactPreferenceFilterValue: 0,
      needle: '',
      activeDropDown: ''
    }
  }

  componentDidMount() {
    this.getContent()
  }

  async getContent(force?: boolean) {
    const result: ServerResult = await Getter('users')
    this.users.init(result.users, {tags: result.tagList, units: result.units})
    this.setState({
      tags: result.tagList.map((t: ITag) => new TagDTO(t)),
      books: result.books.filter((b) => b.right?.value !== 2227).map((b: IBook) => new BookDTO(b)),
      showEmailList: false
    })
    if (force) {
      this.forceUpdate()
    }
  }

  displayEmailListOfMarked() {
    if (!this.state.showEmailList) {
      return <button
        onClick={() => this.setState({showEmailList: true})}
        className='w3-button w3-border w3-border-orange'
      >
        Zeige E-Mails der Selektieren
      </button>
    }

    const markedUsers = this.users.getByIds(this.marked.list())
    const emails = markedUsers.map(u => u.email)
    return <>
      <button
        onClick={() => this.setState({showEmailList: false})}
        className='w3-button w3-border w3-border-orange'
      >
        Verstecke E-Mail Liste
      </button>
      <a href={`mailto:${emails.join(';')}`}><button
        className='w3-button w3-border w3-border-orange'
      ><Send /> {emails.length} selektierten E-Mail schreiben</button></a>
    </>
  }

  ContactPreferenceFilter() {
    const ps = PrivacyRuleList
    const gv = this.state.contactPreferenceFilterValue
    const ddName = 'contactFilterDropDown'
    const ddActiveClass = this.state.activeDropDown === ddName ? 'w3-show' : ''
    const buttonClass = 'dditem w3-bar-item w3-button w3-ripple'
    return <div className='w3-dropdown-click'>
      <div className='w3-button w3-border w3-border-blue'
        onClick={() => {
          this.setState({activeDropDown: (this.state.activeDropDown === ddName) ? '' : ddName})
        }}
      >KontaktPräferenz-Filter</div>
      <div className={`w3-dropdown-content w3-bar-block w3-border ${ddActiveClass}`}>
        {
          ps.map(p => {
            const active = (gv & p.value) === p.value && gv > 0
            const flip = () => {
              let newValue = 0
              if (active) {
                newValue = gv ^ p.value
              } else {
                newValue = gv | p.value
              }
              console.log('newValue', newValue)
              return newValue
            }
            return <button
              key={`cpfi-${p.value}`}
              onClick={() => {
                this.setState({
                  contactPreferenceFilterValue: flip(),
                  showEmailList: false
                })
              }}
              className={`${buttonClass} ${((active) ? 'active' : '')}`}
            >{p.name}</button>
          })
        }
      </div>
    </div>
  }

  setTagForSelectedUsers(tags: string, _self: any) {
    this.users.getByIds(this.marked.list()).forEach(u => u.addTags(tags))
  }

  renderRoleManager(user: UserDTO) {
    if (user.roles.some((r) => r.search('UNREGISTERED') > -1)) {
      return <button
        title='Noch nicht registriert'
        style={{backgroundColor: 'blue', color: 'white'}}
        className='w3-button'
      >
        <Clock />
      </button>
    }
    if (user.roles.some((r) => r.search('selfRegisterNotEndorsed') > -1)) {
      return <button
        title='User hat sich selbst registriert, dies aber noch nicht per Bestätigungslink bestätigt'
        style={{backgroundColor: 'red', color: 'white'}}
        className='w3-button'
      >
        <Slash />
      </button>
    }
    if (user.roles.some((r) => r.search('selfRegisterEndorsed') > -1)) {
      return <button
        title='User wartet auf Bestätigung!'
        style={{backgroundColor: 'orange', color: 'white'}}
        className='w3-button'
      >
        <Slash />
      </button>
    }
    if (user.roles.some((r) => r.search('PASSWORDRESET') > -1)) {
      return <button
        title='Passwortmail wartet auf Antwort vom User'
        style={{backgroundColor: 'green', color: 'white'}}
        className='w3-button'
      >
        <Mail />
      </button>
    }
    // here comes a drop down to set user role
    const availableRoles = [
      {
        name: 'User',
        key: 'USER',
        symbol: <User />
      },
      {
        name: 'Admin',
        key: 'ADMIN',
        symbol: <Star
            className={`w3-yellow`}
          />
      },
      {
        name: 'Büro',
        key: 'OFFICE',
        symbol: <Printer />
      },
      {
        name: 'Interne BTC (Berater, Trainer, Coach)',
        key: 'IBTC',
        symbol: <Home />
      },
      {
        name: 'Externe BTC (Berater, Trainer, Coach)',
        key: 'EBTC',
        symbol: <Cloud />
      }
    ]
    const currentRole = availableRoles.reverse().find(ar => {
      if (user.roles.some((r) => r.search(ar.key) > -1)) {
        return true
      }
    }) || availableRoles[0]
    availableRoles.reverse()
    return <div className="w3-dropdown-hover">
      <button className="w3-button" title={currentRole.name}>{currentRole.symbol}</button>
      <div className="w3-dropdown-content w3-bar-block w3-border">
        {
          availableRoles.map((ar, index) => {
            return <button
                key={`role-drop-down-${user.id}-${ar.key}-${index}`}
                className="w3-bar-item w3-button"
                onClick={() => {
                  user.makeRole(ar.key)
                  this.forceUpdate()
                }}
              >{ar.symbol} {ar.name}</button>
          })
        }
      </div>
    </div> 
    /*
    if (user.roles.some((r) => r.search('ADMIN') > -1)) {
      return <button
        title='Admin - zum entfernen: klicken'
        onClick={() => {
          if (window.confirm(`User ${user.email} wirklich Admin rechte entziehen?`)) {
            user.revokeAdmin().then(() => this.forceUpdate())
          }
        }}
        style={{backgroundColor: '#fcea17'}}
      >
        <Star />
      </button>
    }
    return <button
      title='Normaler User - zum Admin machen?: klicken!'
      onClick={() => {
        if (window.confirm(`User ${user.email} wirklich zu Admin machen?`)) {
          user.makeAdmin().then(() => this.forceUpdate())
        }
      }}
    >
      <User />
    </button>
    */
  }

  renderUser(user: UserDTO) {
    const isActive = this.marked.has(user.id)
    const activeString = isActive ? 'active' : ''
    const avatar = user.getAvatarUrl()
    const bgAvatar = avatar ? `url("${avatar}")` : 'none'
    return <div key={user.id} className='user w3-border' title={`${user.id}`}>
      <div
        className={`marked ${activeString}`}
        onClick={() => {
          this.marked.toggle(user.id)
          this.setState({
            showEmailList: false
          })
          this.forceUpdate()
        }}
        style={{backgroundImage: bgAvatar}}
      ></div>
      <span><a href={`mailto:${user.email}`}>{user.email}</a></span>
      <span title='Vorname'>{user.getPropVal1('personalData', 'firstname')}</span>
      <span title='Nachname'>{user.getPropVal1('personalData', 'surname')}</span>
      <span title='Firmenname'>{user.getPropVal1('personalData', 'company')}</span>
      
      {
        user.listUnits().filter(unitKind => unitKind.list.length > 0).map(unitKind => <div key={unitKind.key}>
          {unitKind.name}
          {unitKind.list.map((unit, index) => <span
            key={unit.id}
            className='underlineHover'
            onClick={() => {
              this.unitFilter.toggle(unit.id)
              this.forceUpdate()
            }}
          >
            {index > 0 ? ', ' : ': '}
            {unit.name}
          </span>)}
        </div>)
      }

      <div>
        Tags:
        {user.getTags().map((tag, index) => <span key={index}>{tag.name}</span>)}
      </div>
      <div className='w3-margin'>
        {
          user.getPrivacySettings().map(item => <span key={`privacy-setting-${item.value}`}>
            {item.isSet &&
              <span title={item.name}>✔</span>
            }
            {!item.isSet &&
              <span title={item.name}>❌</span>
            }

          </span>)
        }
      </div>
      <div className='w3-padding'>
      <button
        title='User löschen'
        className='w3-button'
        onClick={() => {
          if (window.confirm(`User ${user.email} wirklich löschen?`)) {
            user.delete().then(() => {
              this.getContent()
            })
          }
        }}
      ><Trash /></button>
      <button
        title='Passwort Mail schicken'
        className='w3-button'
        onClick={async () => {
          if (window.confirm(`User ${user.email} den Einladungslink schicken?`)) {
            await user.sendPasswordMail()
            this.forceUpdate()
          }
        }}
      ><Send /></button>
      
      { this.renderRoleManager(user) }
      {
        user.hasProp('activity', 'lastLogin') &&
        <button className='w3-panel w3-green' title='User erfolgreich eingeloggt'>✔</button>
      }
      <UserInfo user={user} />
      </div>
    </div>
  }

  deleteTagFromSelectedUsers(tag: TagDTO) {
    if (!window.confirm(`Tag ${tag.name} wirklich löschen?`)) {
      return
    }
    this.users.getByIds(this.marked.list()).forEach((u) => {
      u.deleteTag(tag.id)
    })
    this.forceUpdate()
  }

  renderTagFilter(users: UserDTO[]) {
    const showTrash = (this.marked.size() > 0)
    const ddName = 'tagFilterDropDown'
    const ddActiveClass = this.state.activeDropDown === ddName ? 'w3-show' : ''
    // Shows all tags if nothing is selected.
    // If something is selected: shows the remaining tags
    // If we click on a tag, we filter by this and the current filter
    const buttonClass = 'dditem w3-bar-item w3-button w3-ripple'
    let showTags = this.state.tags.filter(
      (t) => users.some((u) => u.tagIds.some((ti) => ti === t.id))
    )
    return <div className='w3-dropdown-click'>
      <div className='w3-button w3-border w3-border-blue'
        onClick={() => {
          this.setState({activeDropDown: (this.state.activeDropDown === ddName) ? '' : ddName})
        }}
      >Tagfilter</div>
      <div className={`w3-dropdown-content w3-bar-block w3-border ${ddActiveClass}`}>
        <div
          className='dditem w3-bar-item w3-button w3-ripple w3-red'
          onClick={() => {
            this.currentTags = new Map()
            this.forceUpdate()
          }}
        >Reset</div>
        {
          this.marked.size() > 0 &&
          <div className={buttonClass}>
            <div>Tag setzen:</div>
            <InputA
            resetOnSend={true}
            returnVal={
              (rv: string, self: any) => {
                self.setTagForSelectedUsers(rv, self)
                self.setState({
                  currentTag: '',
                  showEmailList: false
                })
                // self.forceUpdate()
              }
            }
            parent={this}
            />
          </div>
        }
        {
          showTags.map((st) => {
            const active = this.currentTags.has(st.id)
            return <div
              key={st.id}
              className={`${buttonClass} ${active ? 'active' : ''}`}
            >
              <span
                key={st.id}
                onClick={() => {

                  if (active) {
                    this.currentTags.delete(st.id)
                  } else {
                    this.marked.reset()
                    this.currentTags.set(st.id, true)
                  }
                  this.forceUpdate()
                }}
              >
                {st.name}
              </span>
              {
                showTrash &&
                <Trash
                  className={`x`}
                  onClick={() => this.deleteTagFromSelectedUsers(st)}
                />
              }
            </div>
          })
        }
      </div>
    </div>
  }

  renderRoleFilter() {
    const ddName = 'roleFilterDropDown'
    const ddActiveClass = this.state.activeDropDown === ddName ? 'w3-show' : ''
    const roles: {name: string, id: TRole}[] = [
      {
        name: 'Alle',
        id: 'ANY'
      },
      {
        name: 'Admins',
        id: 'ADMIN'
      },
      {
        name: 'Unregistriert',
        id: 'UNREGISTERED'
      },
      {
        name: 'Selbstregistrierer unbestätigt',
        id: 'selfRegisterNotEndorsed'
      },
      {
        name: 'Selbstregistrierer warte auf Admin',
        id: 'selfRegisterEndorsed'
      },
      {
        name: 'User',
        id: 'USER'
      },
      {
        name: 'Warte auf User Passwort',
        id: 'PASSWORDRESET'
      }
    ]
    const buttonClass = 'dditem w3-bar-item w3-button w3-ripple'
    return <div className='w3-dropdown-click'>
      <div className='w3-button w3-border w3-border-blue'
        onClick={() => {
          this.setState({activeDropDown: (this.state.activeDropDown === ddName) ? '' : ddName})
        }}
      >Rollenfilter</div>
      <div className={`w3-dropdown-content w3-bar-block w3-border ${ddActiveClass}`}>
        {
          roles.map(r => <button
            key={`rolebutton-${r.id}`}
            className={`${buttonClass} ${this.state.roleFilter === r.id ? 'active' : ''} `}
            onClick={() => {
              this.setState({
                roleFilter: r.id,
                showEmailList: false
              })
            }}
          >
            {r.name}
          </button>)
        }
      </div>
    </div>

  }

  filterUser() {
    if (this.currentTags.size === 0 && this.state.roleFilter === 'ANY' && this.state.contactPreferenceFilterValue === 0 && !this.state.needle && this.unitFilter.empty()) {
      return this.users.list()
    }
    return this.users.list().filter((u) => {
      let okay = true
      // Filter Users by Units:
      if (!this.unitFilter.hasEvery(u.unitIds)) { return false }
      this.currentTags.forEach((_v: boolean, k: number) => {
        if (!u.tagIds.some((id) => k === id)) {
          okay = false
        }
      })
      if (this.state.roleFilter != 'ANY' && this.state.roleFilter === 'USER' && u.roles.some((r) => r.search(/ADMIN|UNREGISTERED/) > -1)) {
        return false
      }
      if (this.state.roleFilter != 'ANY' && !u.roles.some((r) => r.search(this.state.roleFilter) > -1)) {
        return false
      }
      // Filter by ContactPreference of user:
      if (!u.contactOkBitwise(this.state.contactPreferenceFilterValue)) { return false }
      // Filter by needle:
      const needles = this.state.needle.split(' ')
      if (this.state.needle) {
        if (!needles.every((n) => {
          const needle = new RegExp(n, 'i')
          return (
            u.name.search(needle) > -1 ||
            u.email.search(needle) > -1 ||
            u.getPropVal1('personalData', 'company').search(needle) > -1 ||
            u.tags.some((t) => t.name.search(needle) > -1) ||
            u.units.some(u => u.name.search(needle) > -1)
          )
        }
      )) { return false }
      }
      return okay
      // u.tagIds.every((id) => this.currentTags.get(id) !== undefined)
    })
  }

  renderBooks() {
    const markedUsers = this.users.getByIds(this.marked.list())
    return <div>
      {
        this.state.books.map(
          (b: BookDTO) => {
            let bookActiveCount: number = 0
            markedUsers.forEach(u => {
              if (u.bookIds.some((bid: number) => bid === b.id)) {
                bookActiveCount += 1
              }
            })
            const bookIsActive = (bookActiveCount === markedUsers.length)
            const activeClass = bookIsActive ? 'active' : (bookActiveCount > 0) ? 'some' : ''
            return <div
                key={b.id}
                className={`userBookItem ${activeClass}`}
                onClick={
                  () => {
                    if (bookIsActive) {
                      markedUsers.forEach((u) => u.removeBook(b.id))
                    } else {
                      markedUsers.forEach((u) => u.addBook(b.id))
                    }
                    this.forceUpdate()
                  }
                }
              >
                {b.name}
                <span className='floatRight'>({b.id})</span>
              </div>
          }
        )
      }
    </div>
  }

  freeSearch() {
    return <div className="freeSearch">
      <InputA
        className='inline-block'
        returnVal={
          (rv: string, _self: any) => {
            this.setState({needle: rv})
          }
        }
        value={this.state.needle}
        placeholder='Freitext-Suche'
        />
      </div>
  }

  render() {
    const showUsers = this.filterUser()
    // Send Data to Unit Select:
    /*
    this.setAvailableUserForUnits(this.users.getSelectInfo({
      users: showUsers,
      marked: this.marked.list(),
    }))
    */
    return <div className='userList'>
      <button onClick={() => mainservice.navTo([['view', 'adminlogin']])}>&larr; Dashboard</button>
      <button onClick={() => mainservice.navTo([['view', 'BulkGenerateUsers']])}>Nutzer hinzufügen</button>
      <div>
        <div className='users'>
          <UnitDD
            selectedKeys={(keys: number[]) => {
              console.log('handle selected keys', keys)
            }}
            pickedUnitFilters={this.unitFilter.list()}
            /*
            setAvailableUser={(f) => {
              this.setAvailableUserForUnits = f
            }}
            */
            availableUsers={
              this.users.getSelectInfo({
                users: showUsers,
                marked: this.marked.list(),
              })
            }
            unitTypes={['company', 'event']}
            setUnitFilter={(id: number) => {
              this.unitFilter.toggle(id)
              this.forceUpdate()
            }}
            triggerReloadData={async (_userIds: number[], force?: boolean) => {
              await this.getContent(force)
            }}
          />
          {
            this.renderTagFilter(showUsers)
          }
          {
            this.renderRoleFilter()
          }
          {
            this.ContactPreferenceFilter()
          }
          {this.freeSearch()}
          {
            this.displayEmailListOfMarked()
          }

          {
            this.marked.size() === showUsers.length
            ? <button
              className='w3-button w3-border w3-border-orange'
              onClick={() => {
                this.marked.reset()
                this.setState({
                  showEmailList: false
                })
              }}
            >Alle abwählen</button>
            : <button
              className='w3-button w3-border w3-border-orange'
              onClick={() => {
                showUsers.forEach((u) => {
                  this.marked.set(u.id)
                })
                this.setState({
                  showEmailList: false
                })
                this.forceUpdate()
              }}
            >Alle sichtbaren auswählen</button>
          }

          <div>{showUsers.length} User sichtbar</div>
          <div>{this.marked.size()} User ausgewählt</div>

          {
            this.users.list().length
            ? showUsers.map((user) => this.renderUser(user))
            : <Spinner />
          }
        </div>
        <div className='books'>
          {
            this.renderBooks()
          }
        </div>
      </div>
    </div>
  }
}

function UserInfo(props: {
  user: UserDTO
}) {
  const u = props.user
  const [show, setShow] = useState(false)
  const avatar = u.getAvatarUrl()
  return <>

      <button
      className={`w3-right`}
      onClick={() => setShow(!show)}
      >
      <Info />
      </button>
    {
      show &&
      <div className='notice-box wide w3-row'>
        <div className='pointer w3-right w3-xlarge w3-hover-opacity' onClick={() => setShow(false)}>&times;</div>
        <div className='w3-col m6'>
          {
            u.getUserPersonalDataList().map((item, index) => <div key={`personal-data-category-${index}`}>
              <h3>{item.name}</h3>
              {
                item.data.filter(item => item.value).map(i => <div
                  key={`personal-data-item-${i.key}`}
                >
                  {i.name}: {i.value}
                </div>)
              }
            </div>)
          }
        </div>
        {
          avatar &&
          <div className='w3-col m6 avatar'>
            <img src={avatar} />
          </div>
        }
        <div className='w3-col m6'>
          {
            u.getPrivacySettings().map(item => <div key={`privacy-setting-${item.value}`}>
              {item.isSet &&
                <span>✔</span>
              }
              {!item.isSet &&
                <span>❌</span>
              }
              {item.name}
            </div>)
          }
        </div>
        <div className='w3-col m6'>
          {
            u.listUnits().map(ui => {
              if (ui.list.length > 0) {
                return <div key={`ui-list-item-${ui.key}`}>
                  <h3>{ui.name}</h3>
                  <ul>
                    {ui.list.map(g => <li key={`ui-liste-unit-${g.id}`}>
                      {g.name}
                    </li>)}
                  </ul>
                </div>
              }
            })
          }
        </div>
      </div>
    }
  </>
}
