import config from '../config.json'
import PageDTO, {IPage} from './PageDTO'
import PageElementDTO from './PageElementDTO'
import DoublePagePropDTO, {IDoublePageProp} from './DoublePagePropDTO'
import FileDTO, {IFile} from './FileDTO'
import {parseStringToServer} from '../services/TextConverter'

type ElementBlockNextPage = {id: number, block: boolean}

export default class DoublePageDTO {
  public id: number = 0
  public sortNumber: number = 0
  public pages: PageDTO[] = []
  public parentItem: any
  public nextPageEnabled: boolean = true
  public props: DoublePagePropDTO[] = []
  private elementBlockNextPage: ElementBlockNextPage[] = []
  public picture: FileDTO | undefined
  public currentDisplayer: any = undefined
  public sourceBookId: number = -1
  public sourceBookName: string = ''
  public anchorId: number = -1
  public collectionReference: number = -1
  constructor(o: IDoublePage, parentItem?: any) {
    if (parentItem) {
      this.parentItem = parentItem
    }
    this.initVars(o)
  }

  public initVars(o: IDoublePage) {
    this.id = o.id || -1
    this.sortNumber = o.sortNumber * 0.1
    this.pages = o.pages.sort((a, b) => {
      return a.sortNumber < b.sortNumber ? -1 : 1
    }).map((p) => new PageDTO(p, this))
    this.props = (o.props || []).map((p) => new DoublePagePropDTO(p))
    if (o.picture && o.picture.id > -1) {
      this.picture = new FileDTO(o.picture as IFile)
    }
    this.sourceBookId = o.sourceBookId || -1
    this.sourceBookName = o.sourceBookName || ''
    this.anchorId = o.anchorId || -1
    this.collectionReference = o.collectionReference || -1
  }

  public async loadFromServer() {
    const response = await fetch(`${config.apiPrefix}DoublePage/${this.id}`)
    const responseJson = await response.json()
    this.initVars(responseJson);
  }

  // Next Page is only available if all blocker do unblock:
  public blockNextPage(elementId: number, block: boolean) {
    let item = this.elementBlockNextPage.find(
      (item: ElementBlockNextPage) => item.id === elementId
    )
    if (!item) {
      this.elementBlockNextPage.push({id: elementId, block: block})
    } else {
      item.block = block
    }
    this.nextPageEnabled = !this.elementBlockNextPage.some(
      (item: ElementBlockNextPage) => item.block
    )
    // console.log('NEXT PAGE AVAILABLE', this.nextPageEnabled)
  }


  public async setSortNumber(sortNumber: number, currentSortNumber?: number) {
    if (sortNumber === undefined) { return }
    currentSortNumber = (currentSortNumber !== undefined) ? currentSortNumber : this.sortNumber
    const correcture = (currentSortNumber < sortNumber) ? 1 : -1
    await fetch(
      `${config.apiPrefix}DoublePage/${this.id}/setSortNumber/${(sortNumber * 10) + correcture}`
    )
  }



  public async remove() {
    await fetch(`${config.apiPrefix}DoublePage/${this.id}/remove`)
  }

  public async addPage(position: number) {
    await fetch(
      `${config.apiPrefix}DoublePage/${this.id}/addPage/${position * 10 - 1}`
    )
  }

  public getAllPageElements(): PageElementDTO[] {
    let out: PageElementDTO[] = []
    this.pages.forEach((p) => {
      out = out.concat(p.getAllPageElements())
    })
    return out
  }

  public async addDoublePageProp(doublePageProp: DoublePagePropDTO) {
    const e = doublePageProp.get()
    await fetch(
      `${config.apiPrefix}DoublePage/${this.id}/addProp/${e.key1}/${e.key2}/${parseStringToServer(e.value1)}/${parseStringToServer(e.value2)}`
    )
    return true
  }

  public async addProp(d: IDoublePageProp) {
    // console.log('doublepage dto add prop', d)
    const prop = new DoublePagePropDTO({
      id: -1,
      key1: d.key1,
      key2: d.key2,
      value1: d.value1,
      value2: d.value2
    })
    this.props = this.props.filter(
      (pP) => !(pP.key1 === d.key1 && pP.key2 === d.key2)
    )
    this.props.push(prop)
    return await this.addDoublePageProp(prop)
  }

  public async removeProp(key1: string, key2: string) {
    this.props = this.props.filter((pP) => !(pP.key1 === key1 && pP.key2 === key2))
    await fetch(
      `${config.apiPrefix}DoublePage/${this.id}/removeProp/${key1}/${key2}`
    )
  }

  public getProp(key1: string, key2: string) {
    return this.props.find((pP) => pP.key1 === key1 && pP.key2 === key2)
  }

  public getPropValue1(key1: string, key2: string, defaultValue?: string): string {
    const result = this.getProp(key1, key2)
    if (result) { return result.value1 || defaultValue || '' }
    return defaultValue || ''
  }

  public getCssClass(): string {
    const colorClass = this.props.filter(
      (pP) => pP.key1 === 'colorClass').map((pP) => `set-${pP.key2}-${pP.value1}`
    ).join(' ') || ''
    const columnClass = this.props.filter((pP) => pP.key1 === 'layout').map((pP) => `${pP.key1}_${pP.key2}_${pP.value1}_${pP.value2}`).join(' ') || ''
    return `${colorClass} ${columnClass}`
  }

  public async setPicture(id: number) {
    const sR = await fetch(
      `${config.apiPrefix}DoublePage/${this.id}/setPicture/${id}`
    )
    const picture = await sR.json()
    this.picture = new FileDTO(picture)
  }

  public async removePicture() {
    await fetch(`${config.apiPrefix}DoublePage/${this.id}/removePicture`)
    this.picture = undefined
  }

  public getCssBGPicture(): string | false {
    if (!this.picture) { return false }
    return this.picture.getCSSBackground()
  }

  public getPictureId() {
    if (this.picture) {
      return this.picture.id
    }
    return -1
  }

  public getPicture() {
    if (this.picture) {
      return this.picture
    }
    return false
  }

  // Obsolete?:
  public async setLayout(name: string) {
    const layout = this.props.find((p) => p.key1 === 'layout')
    if (!layout) {
      this.addProp(new DoublePagePropDTO({
        id: -1, key1: 'layout', key2: '-', value1: name, value2: '-'
      }))
      return
    }
    layout.setValue1(name)
  }

  // Obsolete?:
  public getLayout() {
    const layout = this.props.find((p) => p.key1 === 'layout')
    if (layout) {
      return layout.value1
    }
  }

  public async clone(targetBookId: number, sortNumber: number) {
    console.log('clone - current sortNumber', sortNumber)
    await fetch(
      // `${config.apiPrefix}DoublePage/${this.id}/clone/${targetBookId}/${sortNumber * 10 - 1}`
      `${config.apiPrefix}DoublePage/${this.id}/clone/${targetBookId}/-1`
    )
    // /api/DoublePage/{id}/clone/{targetBookId}/{targetSortNumber}
  }

}

export interface IDoublePage {
  id?: number
  sortNumber: number
  pages: IPage[]
  props?: IDoublePageProp[]
  picture: IFile | {id: number}
  sourceBookId?: number
  sourceBookName?: string
  anchorId?: number
  collectionReference?: number | null
}
